import * as actionTypes from './actions';

export const initialState = {
    initial: true,
    isLoading: false,
    saveToUseCount: null,
    error: null
};

// ===========================|| SAVETO REDUCER ||=========================== //

const saveToReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SAVETO_USE_INCREMENT_START:
            return {
                ...state,
                initial: false,
                isLoading: true
            };
        case actionTypes.SAVETO_USE_INCREMENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                saveToUseCount: action.data,
                error: null,
                initial: false
            };
        case actionTypes.SAVETO_USE_INCREMENT_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error,
                saveToUseCount: null,
                initial: false
            };
        case actionTypes.SAVETO_USE_INCREMENT_CLEAR:
            return initialState;
        default:
            return state;
    }
};

export default saveToReducer;
