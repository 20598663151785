import React, { createContext, useContext, useEffect, useState } from 'react';
import Loader from 'ui-component/Loader';
import { getBrowserLocale } from '../services/helper';
import enLocale from '../scripts/locale_en.json';

const queryString = require('query-string');

const parsed = queryString.parse(window.location.search);
const defaultLocale = getBrowserLocale() || 'en';
const hl = decodeURIComponent(parsed.hl ? parsed.hl : defaultLocale);
const LanguageContext = createContext();

export function useLanguage() {
    return useContext(LanguageContext);
}

let localeData = {};

function t(key, defaultValue = '') {
    return localeData[key] || defaultValue;
}

export function LanguageProvider({ children }) {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const init = async () => {
            try {
                if (hl === 'en') {
                    throw new Error('language available');
                }
                const data = await fetch(`/_locales/${hl}/messages.json`);
                localeData = await data.json();
            } catch (e) {
                localeData = enLocale;
            }
            setLoading(false);
        };
        init();
    }, []);
    const value = { t, hl };
    return <LanguageContext.Provider value={value}>{loading ? <Loader /> : children}</LanguageContext.Provider>;
}
