/* eslint-disable jsx-a11y/no-redundant-roles */
import React from 'react';
import logo from '../../assets/images/logo.png';
import constants from '../../constants';

export default function Header() {
    return (
        <header role="banner" className="ui-section-header">
            <div className="ui-layout-container">
                <div className="ui-section-header__layout ui-layout-flex">
                    <a className="a" href="/" role="link" aria-label="#">
                        <img
                            className="img"
                            src={logo}
                            alt="Logo"
                            style={{
                                width: '40px',
                                height: '40px',
                                borderRadius: '8px'
                            }}
                        />
                    </a>
                    <a
                        href={constants.extension.saveTo.chrome.url}
                        role="link"
                        aria-label="#"
                        className="a ui-component-button ui-component-button-small ui-component-button-primary"
                    >
                        Add to Chrome
                    </a>
                </div>
            </div>
        </header>
    );
}
