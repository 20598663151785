// assets
import { IconPhoto, IconManualGearbox, IconInfoSquare } from '@tabler/icons';

// constant
const icons = {
    IconPhoto,
    IconManualGearbox,
    IconInfoSquare
};

// ===========================|| DASHBOARD MENU ITEMS ||=========================== //

const dashboard = {
    id: 'dashboard',
    title: 'Pages',
    type: 'group',
    children: [
        {
            id: 'authentications',
            title: 'Sync Contact',
            type: 'item',
            url: '/',
            icon: icons.IconPhoto,
            breadcrumbs: false
        },
        {
            id: 'settings',
            title: 'Settings',
            type: 'item',
            url: '/settings',
            icon: icons.IconManualGearbox,
            breadcrumbs: false
        },
        {
            id: 'authentication323',
            title: 'Getting Started',
            type: 'item',
            url: '/landing',
            icon: icons.IconInfoSquare,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
