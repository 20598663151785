const constants = {
    freeOCRLimit: 10,
    waitTimeInMiliSeconds: 5000,
    webhook: {
        baseUrl: 'https://webhook.fxnoob.workers.dev'
    },
    discord: {
        voice_typing_chrome_extension_channel:
            'https://discord.com/api/webhooks/878875640751869982/_61tPhJd4EvkPKO48EnMAMFv78KDR3lfvOzUgMTqynrNqpWqK-5bZgX1LtsdkhIxMBcd',
        dictionary_browser_extension_channel:
            'https://discord.com/api/webhooks/968529750303842335/2aF1JrFlGkU9oeXZf5qvDrtVWU6irEE4-X23KqIiRWztJEWQ7slme8GHokn0Urg9GhdZ'
    },
    extension: {
        saveTo: {
            tutorial: 'https://www.youtube.com/watch?v=LoPFvPFeia4',
            embed: 'https://www.youtube.com/embed/LoPFvPFeia4',
            chrome: {
                id: 'ofifhemkgcbeneclidajibkcjllgmjfp',
                url: 'https://chrome.google.com/webstore/detail/save-to/ofifhemkgcbeneclidajibkcjllgmjfp'
            },
            opera: {
                id: 'pfkikbcnkmagkibicfmdpjgjbgjpghgk',
                url: 'https://addons.opera.com/en-gb/extensions/details/dictionary-oxford-webster-and-wikipedia/'
            },
            edge: {
                id: 'pbcjplaagkgfgihmbcbbmfdadhbekpeb',
                url: 'https://microsoftedge.microsoft.com/addons/detail/dictionary-oxford-webs/pbcjplaagkgfgihmbcbbmfdadhbekpeb'
            },
            firefox: {
                url: 'https://addons.mozilla.org/en-US/firefox/addon/dictionary/'
            },
            pricing: {
                priceId: {
                    dev: 'price_1L2PXgSITeZHFPIyqOv5yPSA', // 5 dollars
                    prod: 'price_1KrbuCSITeZHFPIyHYKGIXTO', // 5 dollars
                    get value() {
                        return process.env.NODE_ENV === 'development'
                            ? constants.extension.dictionary.pricing.priceId.dev
                            : constants.extension.dictionary.pricing.priceId.prod;
                    }
                }
            },
            paymentCancelURL: {
                dev: 'https://localhost:3000/pay-for-dictionary',
                prod: 'https://app.imagetext.xyz/pay-for-dictionary',
                get value() {
                    return process.env.NODE_ENV === 'development'
                        ? constants.extension.dictionary.paymentCancelURL.dev
                        : constants.extension.dictionary.paymentCancelURL.prod;
                }
            },
            paymentSuccessURL: {
                dev: 'https://localhost:3000/confirm-payment-for-dictionary',
                prod: 'https://app.imagetext.xyz/confirm-payment-for-dictionary',
                get value() {
                    return process.env.NODE_ENV === 'development'
                        ? constants.extension.dictionary.paymentSuccessURL.dev
                        : constants.extension.dictionary.paymentSuccessURL.prod;
                }
            },
            stripe: {
                apiKey: {
                    dev: 'pk_test_51J5SteSITeZHFPIy2b5hp7IIv8ji44I9i5JKYBJ77gYhYQau7jTYyDnjGKdCTxzi5lZLXotxA8vr8E7uMkPE7TTP00pJXVqtQj',
                    prod: 'pk_live_51J5SteSITeZHFPIygmFxGATLC1rCmgyYPE5sE3gZNavyRqBqEWeOW6Wcvk3bNV4dBc1htI9mBJHjJkYDLCgTI9g500ZXwECAu4',
                    get value() {
                        return process.env.NODE_ENV === 'development'
                            ? constants.extension.dictionary.stripe.apiKey.dev
                            : constants.extension.dictionary.stripe.apiKey.prod;
                    }
                }
            }
        }
    },
    premium: {
        endpoint: 'https://saveto.imagetext.xyz/',
        pricing: {
            priceId: 'price_1KjRKMSH1Cgu43ifwKegOKnZ',
            testPriceId: 'price_1Kx3roSH1Cgu43ifdtLC7VlM',
            short: '$10',
            description: '$10 for a lifetime.',
            full: 'inr 759.13 ~ $10'
        },
        paymentConfirmationURL: {
            dev: 'http://localhost:3000/validate/',
            prod: 'https://app.imagetext.xyz/validate'
        },
        paymentCancelURL: {
            dev: 'http://localhost:3000/account/',
            prod: 'https://app.imagetext.xyz/account'
        }
    },
    support: {
        feedbackForm: 'https://forms.gle/iaFrarBtMp2YDFhB6',
        youtube: 'https://www.youtube.com/fxnoob'
    },
    server: {
        frontEnd: {},
        endpoint: {
            local: 'http://127.0.0.1:8787',
            prod: 'https://webhook.fxnoob.workers.dev'
        }
    }
};
export default constants;
