/* eslint-disable jsx-a11y/no-redundant-roles */
import React from 'react';

export default function Footer() {
    return (
        <footer role="contentinfo" className="ui-section-footer">
            <div className="ui-layout-container">
                <div className="ui-section-footer__layout ui-layout-flex">
                    <p className="p ui-section-footer--copyright ui-text-note">
                        <small>&copy; Save to</small>
                    </p>
                    <a href="/privacy" role="link" aria-label="#" className="a ui-text-note">
                        <small className="small">Privacy</small>
                    </a>
                    <a href="/terms" role="link" aria-label="#" className="a ui-text-note">
                        <small className="small">Terms</small>
                    </a>
                </div>
            </div>
        </footer>
    );
}
