import React, { createContext, useEffect, useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { app, checkEntry } from '../services/firebaseService';
import { SAVETO_USE_INCREMENT_SUCCESS } from '../store/actions';

import Loader from 'ui-component/Loader';

export const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
    const dispatch = useDispatch();
    const [currentUser, setCurrentUser] = useState(null);
    const [pending, setPending] = useState(true);

    useEffect(() => {
        app.auth().onAuthStateChanged(async (user) => {
            if (user) {
                const { isPaid, saveToUseCount } = await checkEntry('users', user.email);
                user.paid = isPaid;
                user.saveToUseCount = saveToUseCount;
                console.log({ saveToUseCount });
                dispatch({ type: SAVETO_USE_INCREMENT_SUCCESS, data: saveToUseCount });
            }
            setCurrentUser(user);
            setPending(false);
        });
    }, []);

    if (pending) {
        return <Loader />;
    }

    return (
        <AuthContext.Provider
            value={{
                currentUser
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
