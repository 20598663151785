import React from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { Card, CardContent, Grid, Typography } from '@material-ui/core';

// project imports
import constants from '../../../../constants';

// ===========================|| Support Links ||=========================== //

const SupportLinks = () => (
    <Card>
        <CardContent>
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12}>
                    <Link to="/privacy">Privacy Policy</Link>
                </Grid>
                <Grid item xs={12}>
                    <Link to="/terms">Terms and Conditions</Link>
                </Grid>
                <Grid item xs={12}>
                    <a href={constants.support.feedbackForm}>Support</a>
                </Grid>
                <Grid item xs={12}>
                    <Typography>© imagetext.xyz</Typography>
                </Grid>
            </Grid>
        </CardContent>
    </Card>
);

export default SupportLinks;
