import React, { lazy } from 'react';
import Loadable from 'ui-component/Loadable';

// project imports
import MinimalLayout from 'layout/MinimalLayout';

// login routing
const AuthLogin = Loadable(lazy(() => import('views/pages/Login')));

// ===========================|| AUTHENTICATION ROUTING ||=========================== //

const AuthenticationRoutes = ({ isLoggedIn, isPaid }) => ({
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/login',
            element: <AuthLogin />
        }
    ]
});

export default AuthenticationRoutes;
