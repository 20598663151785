// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

export const SAVETO_USE_INCREMENT_START = 'SAVETO_USE_INCREMENT_START';
export const SAVETO_USE_INCREMENT_SUCCESS = 'SAVETO_USE_INCREMENT_SUCCESS';
export const SAVETO_USE_INCREMENT_FAIL = 'SAVETO_USE_INCREMENT_FAIL';
export const SAVETO_USE_INCREMENT_CLEAR = 'SAVETO_USE_INCREMENT_CLEAR';
