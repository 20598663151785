import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import saveToReducer from './saveToReducer';

// ===========================|| COMBINE REDUCER ||=========================== //

const reducer = combineReducers({
    customization: customizationReducer,
    saveTo: saveToReducer
});

export default reducer;
