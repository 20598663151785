import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// settings routing
const Settings = Loadable(lazy(() => import('views/settings')));

// validate routing
const Validate = Loadable(lazy(() => import('views/validate')));

// account routing
const Account = Loadable(lazy(() => import('views/account')));

// ===========================|| MAIN ROUTING ||=========================== //

const MainRoutes = ({ isLoggedIn, isPaid, search, query }) => {
    const useCount = useSelector((state) => state.saveTo.saveToUseCount);
    console.log({ useCount });
    return {
        path: '/',
        element: isLoggedIn ? <MainLayout /> : <Navigate to={`/login${search === '' ? '?' : search}&redirect_to=/`} />,
        children: [
            {
                path: '/',
                element: isPaid || useCount < 10 ? <DashboardDefault /> : <Navigate to={`/account${search === '' ? '?' : search}`} />
            },
            {
                path: '/settings',
                element: isPaid || useCount < 10 ? <Settings /> : <Navigate to="/account" />
            },
            {
                path: '/validate',
                element: isLoggedIn ? <Validate /> : <Navigate to="/login" />
            },
            {
                path: '/account',
                element: <Account />
            }
        ]
    };
};

export default MainRoutes;
