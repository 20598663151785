import React from 'react';
import Header from './header';
import Hero from './hero';
import Features from './features';
import Action from './action';
import Footer from './footer';

export default function Home() {
    return (
        <div className="body">
            <Header />
            <main role="main">
                <Hero />
                <Features />
                <Action />
            </main>
            <Footer />
        </div>
    );
}
